// 100
@font-face {
  font-family: "Metropolis Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Thin"), url("Metropolis-Thin.woff") format("woff");
}

// 100-i
@font-face {
  font-family: "Metropolis Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Thin Italic"),
    url("Metropolis-ThinItalic.woff") format("woff");
}

// 200
@font-face {
  font-family: "Metropolis Extra Light";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Extra Light"),
    url("Metropolis-ExtraLight.woff") format("woff");
}

// 200-i
@font-face {
  font-family: "Metropolis Extra Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Extra Light Italic"),
    url("Metropolis-ExtraLightItalic.woff") format("woff");
}

// 300
@font-face {
  font-family: "Metropolis Light";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Light"), url("Metropolis-Light.woff") format("woff");
}

// 300-i
@font-face {
  font-family: "Metropolis Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Light Italic"),
    url("Metropolis-LightItalic.woff") format("woff");
}

// 400
@font-face {
  font-family: "Metropolis Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Regular"),
    url("Metropolis-Regular.woff") format("woff");
}

// 400-i
@font-face {
  font-family: "Metropolis Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Regular Italic"),
    url("Metropolis-RegularItalic.woff") format("woff");
}

// 500
@font-face {
  font-family: "Metropolis Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Medium"), url("Metropolis-Medium.woff") format("woff");
}

// 500-i
@font-face {
  font-family: "Metropolis Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Medium Italic"),
    url("Metropolis-MediumItalic.woff") format("woff");
}

// 600
@font-face {
  font-family: "Metropolis Semi Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Semi Bold"),
    url("Metropolis-SemiBold.woff") format("woff");
}

//600-i
@font-face {
  font-family: "Metropolis Semi Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Semi Bold Italic"),
    url("Metropolis-SemiBoldItalic.woff") format("woff");
}

// 700
@font-face {
  font-family: "Metropolis Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Bold"), url("Metropolis-Bold.woff") format("woff");
}

// 700-i
@font-face {
  font-family: "Metropolis Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Bold Italic"),
    url("Metropolis-BoldItalic.woff") format("woff");
}

// 800
@font-face {
  font-family: "Metropolis Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Extra Bold"),
    url("Metropolis-ExtraBold.woff") format("woff");
}

// 800-i
@font-face {
  font-family: "Metropolis Extra Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Extra Bold Italic"),
    url("Metropolis-ExtraBoldItalic.woff") format("woff");
}

// 900
@font-face {
  font-family: "Metropolis Black";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Black"), url("Metropolis-Black.woff") format("woff");
}

// 900-i
@font-face {
  font-family: "Metropolis Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Metropolis Black Italic"),
    url("Metropolis-BlackItalic.woff") format("woff");
}

// font classes
// .metropolis {
//   font-family: "Poppins" !important;
// }
.metropolis-100 {
  font-family: "Metropolis Thin";
}

.metropolis-100-i {
  font-family: "Metropolis Thin Italic";
}

.metropolis-200 {
  font-family: "Metropolis Light";
}

.metropolis-200-i {
  font-family: "Metropolis Light Italic";
}

.metropolis-300 {
  font-family: "Metropolis Extra Light";
}

.metropolis-300-i {
  font-family: "Metropolis Extra Light Italic";
}

.metropolis {
  font-family: "Metropolis Regular";
}

.metropolis-i {
  font-family: "Metropolis Regular Italic";
}

.text-bold {
  font-family: "Metropolis Medium";
}

.text-bold-i {
  font-family: "Metropolis Medium Italic";
}

.metropolis-600 {
  font-family: "Metropolis Semi Bold";
}

.metropolis-600-i {
  font-family: "Metropolis Semi Bold Italic";
}

.metropolis-700 {
  font-family: "Metropolis Bold";
}

.metropolis-700-i {
  font-family: "Metropolis Bold Italic";
}

.metropolis-800 {
  font-family: "Metropolis Extra Bold";
}

.metropolis-800-i {
  font-family: "Metropolis Extra Bold Italic";
}

.metropolis-900 {
  font-family: "Metropolis Black";
}

.metropolis-900-i {
  font-family: "Metropolis Black Italic";
}
