.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom scrollbar for the inner scrollable div */
.inner-scroll::-webkit-scrollbar {
  width: 4px;
}

.inner-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.inner-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Position the scrollbar on the left */
.scrollbar {
  width: 4px;
  height: 250px; /* Fixed height for the scrollbar */
  background-color: grey;
  border-radius: 2px;
  overflow: hidden;
}

.scrollbar::before {
  content: '';
  display: block;
  width: 100%;
  background-color: grey;
  height: 100%; /* Adjust to match the dynamic height set in React component */
}

/* Optional: Smooth scrolling for a better user experience */
.inner-scroll {
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
}

.inner-scroll > div {
  direction: ltr; /* Ensure normal text direction */
  padding: 10px; /* Adjust as needed */
}