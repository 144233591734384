// $primary: #f7941d;
// $secondary: #ed1a3b;
// $info: #828282;
// $breadcrumb-divider: ">";

// $theme-colors: (
//   "primary": $primary,
//   "secondary": $secondary,
//   "info": $info,
//   "black-gray": #313335,
// );
@import "node_modules/bootstrap/scss/bootstrap";

// @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Shadows+Into+Light&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import "fonts/fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Manrope:wght@200..800&display=swap");

.home-background {
  background-image: url("web/assets/pages/background.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

body {
  max-width: 100%;
  overflow-x: hidden !important;
  // font-family: "Roboto" !important;
  // font-family: "Metropolis Regular", sans-serif !important;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0 !important;
}

// FONT FAMILY
.ff-manrope {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.ff-ibmplexmono {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.text-bold {
  font-weight: 500 !important;
}

.text-bolder {
  font-weight: 500 !important;
}

.text-extrabold {
  font-weight: 600 !important;
}

///// buttons /////
.sitebtn {
  border-radius: 4px;
}

.btn-dark {
  background: #1f1f1f;
  color: #fff;
  border: 0px;
}

.btn-outline-dark {
  border: 1px solid #1f1f1f;
  color: #1f1f1f;
  font-weight: bold;
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-dark:focus{
  border: 1px solid #1f1f1f;
  outline: none !important;
  box-shadow: none !important;
  color: #1f1f1f;
}


.btn-outline-dark:hover {
  border: 1px solid #1f1f1f !important;
  color: #fff !important;
  background: #1f1f1f !important;
}

.btn-outline-dark:visited {
  border: 1px solid #1f1f1f !important;
  color: #fff !important;
  background: #1f1f1f !important;
}

.btn-dark:hover {
  background: #1f1f1f;
  color: #fff;
  border: 0px;
}

.btn-white {
  background: #ffff;
  color: #1f1f1f;
  border: 0.8px solid #1f1f1f;
}

.btn-white:hover {
  background: #ffff;
  color: #1f1f1f;
  border: 0.8px solid #1f1f1f;
}

.focus-none:focus {
  outline: none;
  box-shadow: none !important;
  border: none !important;
}

///// border radius /////
.rad {
  border-radius: 10px;
}
.rad-4 {
  border-radius: 4px;
}
.rad-8 {
  border-radius: 8px;
}
.rad-12 {
  border-radius: 12px;
}

.text-decoration-underline {
  text-decoration: underline;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #9b7c5c;
  border-radius: 10px;
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.focus-none:focus {
  outline: none;
  box-shadow: none !important;
}


.bg-light {
  background-color: #f7f7f7 !important;
}

.bg-purple {
  background-color: #512179 !important;
}

.text-purple {
  color: #512179 !important;
}

.border-grey {
  border-color: #bbbbbb !important;
}

.border-purple {
  border-color: #512179 !important;
}

.bg-purple {
  background-color: #f3eaff !important;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  min-height: 100%;
}

.slick-track {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;
}

.slick-slide {
  // flex-grow: 1 !important;
  // flex: 1 0 auto !important;
  display: flex !important;
  flex: auto;
  height: unset !important;
  min-height: 100% !important;
}

// .slick-dots {
//   display: flex !important;
//   justify-content: center;
//   padding: 16px 0 !important;
// }

// .slick-dots li {
//   margin: 0 5px !important;
// }


.line-div{
  background-color: #ccc;
  height: 1px;
}

.line-div-vertical{
  background-color: #ccc;
  height: 100%;
  width: 1px;
}

.line-div-vertical-xs{
  background-color: #ccc;
  height: 30px;
  width: 1px;
}

.border-right-vertical{
  border-right: 1px solid #ccc;
}

.line-div-vertical-wt-height{
  background-color: #ccc;
  width: 1px;
}

.outer-circle{
  background-color: #CCCCCC; 
  width: 20px;
  height: 20px; 
  border-radius: 10px;
}

.inner-circle{
  background-color: #6C757D; 
  width: 12px;
  height: 12px; 
  border-radius: 6px;
}

.outer-circle-live{
  background-color: #E1C1FF; 
  width: 20px;
  height: 20px; 
  border-radius: 10px;
}

.inner-circle-live{
  background-color: #542378; 
  width: 12px;
  height: 12px; 
  border-radius: 6px;
}

.live-text {
  color: #542378;
}

.upcoming-text{
  color: #303030
}

///// width and height /////
@media (max-width: 768px) {
  .min-height {
    // min-height: 40vh;
    min-height: 25em;
  }
}
@media (min-width: 768px) {
  .min-height {
    // min-height: 50vh;
    min-height: 31.25em;
  }
}
@media (min-width: 992px) {
  .min-height {
    // min-height: 90vh;
    // min-height: 60vh;
    min-height: 37.5em;
  }
}
.text-white {
  color: white;
}

//// navbar ////
// .active {
//   border-bottom: 2px solid #1f1f1f;
// }

// .navbar-nav > .active > .a {
//   border-bottom: 2px solid #1f1f1f;
// }
// .nav-link {
//   margin-left: 8px;
//   margin-right: 8px;
// }

//// focus-none
.focus-none:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
}

///// accordion @PaymentMethod /////
.accordion-button {
  background-color: #f2f2f2 !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #212529;
}

///// sidenav @CSRDashboard /////
.sidenav .nav.nav-pills .nav-link {
  color: #fff;
}

.sidenav .nav.nav-pills .nav-link.active {
  background: #f2f2f2;
  color: #1f1f1f;
}

.sidenav-div {
  @media (min-width: 768px) {
    width: 15rem;
  }
  @media (min-width: 992px) {
    min-width: 16.5rem;
  }
  // @media (min-width: 1200px) {
  //   min-width: 18rem;
  // }
}

///// dashboardCardRow @CSRDashboard /////
.dashboardCardRow {
  @media (min-width: 992px) {
    min-width: 70%;
  }
  @media (min-width: 1200px) {
    min-width: 60rem;
  }
}

///// annualSpentReport @CSRDashboard /////
.annualSpentReport .nav.nav-pills .nav-link {
  color: #fff;
}

.annualSpentReport .nav.nav-pills .nav-link.active {
  border: 1px solid #f2f2f2;
  background: #1f1f1f;
}

.annualSpentReport {
  @media (min-width: 768px) {
    width: 12rem;
    // width: 70%;
    // max-width: 100%;
  }
  @media (min-width: 992px) {
    width: 12rem;
    // width: 70%;
  }
  // @media (min-width: 1200px) {
  //   min-width: 18rem;
  // }
}

.max-width {
  max-width: 70% !important;
}

.tableData {
  border-top-width: 9px;
  border-right-width: 1px;
  border-color: #f5f5f5;
}

.tableDataViewAll {
  border-top-width: 9px;
  border-right-width: 1px;
  border-color: #ffffff;
}

///// bgs ////
.tableBg {
  background-color: #e0e0e0;
}
.dashboardBg {
  background-color: #f5f5f5;
}

.bg-light {
  background: #f2f2f2 !important;
}

.bg-faint {
  background: #fafafa !important;
}

.bg-white {
  background: #fff !important;
}

.bg-dark {
  background: #1f1f1f !important;
}

.bg-disabled-dark {
  background: #d1d1d1 !important;
}

.cursor-pointer {
  cursor: pointer;
}
///// custom screensize bgs ////

@media (min-width: 576px) {
  .bg-sm-white {
    background: #fff !important;
  }

  .bg-sm-light {
    background: #f2f2f2 !important;
  }

  .bg-sm-dark {
    background: #1f1f1f !important;
  }
}

@media (min-width: 768px) {
  .bg-md-white {
    background: #fff !important;
  }

  .bg-md-light {
    background: #f2f2f2 !important;
  }

  .bg-md-dark {
    background: #1f1f1f !important;
  }
}

@media (min-width: 992px) {
  .max-width {
    max-width: 60% !important;
  }

  .bg-lg-white {
    background: #fff !important;
  }

  .bg-lg-light {
    background: #f2f2f2 !important;
  }

  .bg-lg-dark {
    background: #1f1f1f !important;
  }
}

.text-light {
  color: #fefefe;
}

.text-dark {
  color: #1f1f1f !important;
}

///// custom screensize bgs ////
@media (min-width: 576px) {
  .text-sm-white {
    color: #fff !important;
  }

  .text-sm-light {
    color: #f2f2f2 !important;
  }

  .text-sm-dark {
    color: #1f1f1f !important;
  }
}

@media (min-width: 768px) {
  .text-md-white {
    color: #fff !important;
  }

  .text-md-light {
    color: #f2f2f2 !important;
  }

  .text-md-dark {
    color: #1f1f1f !important;
  }
}

@media (min-width: 992px) {
  .text-lg-white {
    color: #fff !important;
  }

  .text-lg-light {
    color: #f2f2f2 !important;
  }

  .text-lg-dark {
    color: #1f1f1f !important;
  }
}

///// width and height
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }
  
}

.my-switch > .form-check-input {
  height: 1.5em;
  width: 2.875em;
}

.my-switch > .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.25) !important;
}

.my-switch > .form-check-input:checked {
  border-color: #00d54f !important;
  background-color: #00d54f !important;
}

.my-switch > .form-check-input:active {
  background-color: #00d54f !important;
  border-color: rgba(0, 0, 0, 0.25) !important;
}
.bprder-black {
  color: 1px solid black !important;
}
// .my-switch>.form-check-input:focus:not(:checked) {
//   // border-color: #00D54F !important;
// }

.schedule-a-call > .form-control:focus {
  border-radius: 4px !important;
  border-color: white !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.25) !important;
}
.form-control:focus,
.form-select:focus {
  border-radius: 4px !important;
  // border-width: 2px !important;
  border-color: black !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.25) !important;
}

.focus-radius {
  border-radius: 8px !important;
}
.sideImage {
  filter: grayscale(1);
}

#dropdownMenu {
  background-color: #f2f2f2 !important;
  border: 0px;
  color: #1f1f1f;
}

#dropdownMenu:after {
  content: none;
}

#dropdownMenu:focus {
  box-shadow: none;
}
.dropdown-menu.show {
  background-color: #f2f2f2 !important;
}

///// test
.image-container {
  width: 100%;
  height: 100vh; /* Make the container full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  width: auto;
  height: 100%; /* Adjust the height as needed */
  transition: clip-path 0.5s ease-out;
}

///// font sizes
.title-1 {
  // font-size: 80px;
  font-size: 4.25em;
  line-height: 1;
}

.title-2 {
  // font-size: 70px;
  font-size: 2.875em;
  line-height: 1.2;
}

.title-3 {
  font-size: 2.875em;
  line-height: 1.2;
}

.title-4 {
  font-size: 2.875em;
  line-height: 1.2;
}

.title-5 {
  font-size: 1.75em;
  line-height: 1.2;
}

.text-1 {
  font-size: 1.5625em;
}

.text-2,
p {
  font-size: 1.25em !important;
  line-height: 1.2;
}

.text-3 {
  font-size: 0.9375em !important;
}

@media (max-width: 1200px) {
  body {
    font-size: 0.9375em !important;
  }

  .title-1 {
    font-size: 2.1875em !important;
  }

  .title-2 {
    font-size: 1.875em !important;
  }

  .title-3 {
    font-size: 1.5625em !important;
  }

  .title-4 {
    font-size: 1.25em !important;
  }

  .title-5 {
    font-size: 1.75em !important;
  }

  .text-1 {
    font-size: 1.25em !important;
  }

  .text-2,
  p {
    font-size: 1.0625em !important;
  }
}

#lines {
  max-height: 500px;
  width: 100%;
  height: auto;
}

.path1 path,
.path2 path,
.path3 path,
.path4 path,
.path5 path,
.path6 path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 5s linear forwards;
}

// @keyframes draw {
//   to {
//       stroke-dashoffset: 0;
//   }
// }

// .path1 path {
//   animation-delay: 0s;
// }

// .path2 path {
//   animation-delay: 0.5s;
// }

// .path3 path {
//   animation-delay: 1s;
// }

// .path4 path {
//   animation-delay: 1.5s;
// }

// .path5 path {
//   animation-delay: 2s;
// }

// .path6 path {
//   animation-delay: 2.5s;
// }